/* eslint-disable */
import HWLLSPlayer from "./lib/HWLLSPlayer";
// console.log("START---HWLLSPlayer Version", HWLLSPlayer.getVersion());

/**
 * 
 * 配置：
1. 3个域名：推流域名、普通直播播放域名、LLL播放域名。并在租户console配置，LLL播放域名和普通直播拉流要关联好推流域名。（必须）
2. https证书：配置到LLL播放域名上。（必须）
3. 推流要求：H264/无B帧， GOP 2s。（必须）
4. referer配置：配置到LLL播放域名上。（可选）
对接：
1. 华为提供Web SDK和对接文档。
2. 客户按照华为Web SDK的接口开发APP。
测试结果2022-09-27
1. 华为 webrtc 直播延迟 1-2s
2. 文档API(目前只测试了几个常用方法)， 获取音量 getPlayoutVolume 结果一直为0
3. 直播播放端，华为 nova7手机偶尔有画屏现象
4. 苹果手机测试有一次卡死现象，未见报错，刷新界面恢复正常
 */

export const isBrowserSupport = async () => {
  let check = false;
  try {
    check = await HWLLSPlayer.checkSystemRequirements();
  } catch (error) {
    console.error(
      `check browser isSupport error: ${error.getCode()} - ${error.getMsg()}`
    );
  }
  return check;
};

class WebRtc {
  // liveType = "flv"; // webrtc | flv | hls
  constructor(type) {
    // 创建客户端
    console.log("type-------", type);
    this.client = HWLLSPlayer.createClient(type);
  }
  /*************  外部接口  **************** */
  // 检测浏览器是否兼容SDK
  async isBrowserSupport() {
    let check = false;
    try {
      check = await HWLLSPlayer.checkSystemRequirements();
      console.warn("browser isSupport: " + check);
    } catch (error) {
      console.error(
        `check browser isSupport error: ${error.getCode()} - ${error.getMsg()}`
      );
    }
    return check;
  }

  async startPlay(url, options) {
    try {
      console.log(`url, ${url}`);
      await this.client.startPlay(url, options);
      this._onSubscribe(url, options);
      console.log("startPlay success");
    } catch (error) {
      console.error(`"startPlay fail", ${error}`);
      // if (error.getCode && error.getCode() === 50000021) {
      //   let commonClient = HWLLSPlayer.createClient("flv"); // "hls"
      //   await commonClient.startPlay(url, options);
      // }
    }
  }

  stopPlay() {
    try {
      this.client.stopPlay();
      console.log("stopPlay success");
    } catch (error) {
      console.log("stopPlay fail", error);
    }
  }

  setPlayoutVolume(value) {
    const ret = this.client.setPlayoutVolume(value);
    console.log("setPlayoutVolume-----", ret);
  }

  getPlayoutVolume() {
    console.log("getPlayoutVolume-----", this.client.getPlayoutVolume());
    return this.client.getPlayoutVolume();
  }

  // 设置是否开启流信息统计
  streamStatistic(enable, interval, cb) {
    this.client.streamStatistic(enable, interval);

    // this.client.on("media-statistic", (StatisticInfo) => {
    //   console.log("media-statistic video:", StatisticInfo.video);
    //   console.log("media-statistic: audio", StatisticInfo.audio);
    // });

    // this.client.on("network-quality", (NetworkQualityTypes) => {
    //   console.log(
    //     "00000000000network-quality",
    //     this.getNewWorkQuality(NetworkQualityTypes)
    //   );
    //   cb(this.getNewWorkQuality(NetworkQualityTypes));
    // });
  }

  setParameter(parameterKey, parameterValue) {
    this.client.setParameter(parameterKey, parameterValue);
  }

  destoryClient() {
    this.client.destoryClient();
  }

  /*************  私有函数  **************** */
  getNewWorkQuality(key) {
    switch (key) {
      case 0:
        return "网络质量未知";
      case 1:
        return "网络质量极好";
      case 2:
        return "用户主观感觉和极好差不多，但码率可能略低于极好";

      case 3:
        return "网络质量一般，用户主观感受有瑕疵但不影响观看";

      case 4:
        return "网络质量差，勉强能观看但不流畅";

      case 5:
        return "网络质量很差，严重影响用户观看体验";

      case 6:
        return "络质量非常差甚至链接断开，无法观看";
      default:
        return "无状态码";
    }
  }

  /**
   *
   */
  async _onSubscribe(url, options) {
    // 播放成功后，客户端会收到video-start和audio-start事件通知。
    this.client.on("video-start", () => {
      console.log("video-start");
    });

    this.client.on("audio-start", () => {
      console.log("audio-start");
    });

    // 如果播放失败，可根据错误进行业务降级处理。远端流移除事件
    this.client.on("Error", (error) => {
      console.error("如果播放失败----------", error);
      // if (error.errCode === 50000007 || error.errCode === 50000022) {
      //   let commonClient = HWLLSPlayer.createClient("flv") // "hls"
      //   await commonClient.startPlay(url,options)
      // }
    });
  }
}

export default WebRtc;
