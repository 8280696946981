<style >
video {
  width: 100%;
  height: auto;
}
</style>
<template>
  <div>
    <div>
      <div v-if="!isBrowserStatus">浏览器不支持</div>
      <button @click="startPlay">开始播放</button>
      <button @click="stopPlay">暂停播放</button>
      <!-- <button @click="streamStatistic">网络报告</button> -->
      <button @click="setPlayoutVolume(100)">设置音量({{ curVolume }})</button>
      <div>{{ networkQuality }}</div>
    </div>
    <div style="width: 100%; height: 300px" id="liveid"></div>
  </div>
</template>



<script>
/* eslint-disable */

import WebRtc, { isBrowserSupport } from "../HWLLS-Package/webrtc";

// import VConsole from "./vconsole.min";
/**
 *
 */
export default {
  name: "testlls",
  components: {},
  data() {
    return {
      isBrowserStatus: true,
      webrtc: null,
      curVolume: 0,
      networkQuality: "",
      // http://play.whrailway-rmt.cn/channel/wtxw.m3u8
      // http://zjonline.gtrmt.cn/live/gtrmt.m3u8
      liveOption1: {
        url: "http://play.whrailway-rmt.cn/channel/wtxw.flv",
        type: "flv",
      },
      liveOption2: {
        url: "http://play.whrailway-rmt.cn/channel/wtxw.m3u8",
        type: "hls",
      },

      liveOption3: {
        url: "http://zjonline.gtrmt.cn/live/gtrmt.m3u8",
        type: "hls",
      },

      liveOption4: {
        url: "webrtc://lll-pull.gtrmt.cn/live/67518270",
        type: "webrtc",
      },

      liveOption5: {
        url: "http://livepull.gtrmt.cn/live/67518270.m3u8",
        type: "hls",
      },

      liveOption6: {
        url: "http://lll-pull.gtrmt.cn/live/67518270.m3u8",
        type: "hls",
      },
    };
  },
  methods: {
    changePlayStatus() {},

    async startPlay() {
      this.isBrowserStatus = await isBrowserSupport();
      console.log("navigator", navigator);
      this.webrtc = new WebRtc(this.liveOption4.type);
      this.webrtc.startPlay(this.liveOption4.url, {
        elementId: "liveid",
        objectFit: "contain", //cover  contain fill
        muted: false, // true表示静音，false表示不静音。默认值为false。
        showLoading: true,
      });

      this.curVolume = this.webrtc.getPlayoutVolume();
      console.log("-this.curVolume----", this.curVolume);
    },
    stopPlay() {
      this.webrtc.stopPlay();
      if (this.webrtc) {
        this.webrtc.destoryClient();
      }
    },
    setPlayoutVolume(value) {
      this.webrtc.setPlayoutVolume(value);
      this.curVolume = this.webrtc.getPlayoutVolume();
    },
  },
  mounted() {
    this.startPlay();
  },
  created() {
    // var vConsole = new VConsole();
    // console.log("hello world");
  },

  async destroyed() {
    if (this.webrtc) {
      this.webrtc.destoryClient();
    }
  },
};
</script>
